import React from "react";

// Customizable Area Start

import { Box, Checkbox, IconButton, InputAdornment, InputLabel, Snackbar, MenuItem, Select, TextField, ThemeProvider, Typography, createTheme, styled, Button, MobileStepper, Popper, ClickAwayListener } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import ArrowBackwardIos from '@material-ui/icons/ArrowBackIos';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { backgroundSpaImg, headerLogoImg } from "./assets";
import { getRtlStyleValue, getTranslationConfig } from '../../../components/src/helpers';
import { LanguageSwitchDropdown } from '../../../components/src/LanguageSwitchDropdown';
import PhoneInput from 'react-phone-input-2';

const {isArabic } = getTranslationConfig();

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const CustomTypography = styled(Typography)({
  '& .heading': {
    fontSize: '36px',
    fontFamily: 'Ubuntu',
    fontWeight: 700,
    color: '#FFF'
  },
  '& .description': {
    fontSize: '16px',
    fontFamily: 'Ubuntu',
    fontWeight: 400,
    color: '#FFF'
  },
  '& .termsAndCond': {
    color: '#50C',
    fontFamily: 'Ubuntu',
    fontSize: '12px',
    paddingRight: '2px',
    paddingLeft: '4px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  '& .testStyle': {
    fontSize: '12px',
    color: '#0F172A',
    fontFamily: 'ubuntu',
    paddingLeft: '3px'
  },

  '& .txtErrorMsg': {
    color: '#DC2626',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px',
    marginTop: "10px"
  },
  '& .txtMsg': {
    color: '#1B5950',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    paddingTop: '5px'
  },
  '& .title': {
    color: `var(--Basic-Black, #000)`,
    fontFamily: 'Ubuntu',
    fontSize: '24px',
    fontWeight: 700,
  },
  '& .readAndAgree': {
    color: '#0F172A',
    fontFamily: 'Ubuntu',
    fontSize: '12px'
  },
})

const styles = {
  paperContainer: {
    backgroundImage: `url(${backgroundSpaImg})`,
    width: '100%',
    height: '1920px',
    backgroundSize: 'cover',
    "@media only screen and (min-width: 1280px)": {
      minWidth: '100%'
    }
  },
  iconStyle: {
    fontSize: '18px',
    color: '#34D399'
  },
  spaOwnerContainer: {
    backgroundImage: `url(${backgroundSpaImg})`,
    width: '100%',
    height: '1920px',
    backgroundSize: 'contain',
    "@media only screen and (min-width: 1280px)": {
      minWidth: '100%'
    }
  }
};
const StepperView = styled(Box)({
  '& .MuiMobileStepper-dotActive': {
    background: '#FFF !important'
  },
  '& .MuiMobileStepper-dot': {
    background: '#398378'
  }
})
const RegistrationFormView = styled(Box)({
  width: '50%',
  borderRadius: '24px 24px 0px 0px',
  background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
  justifyContent: 'center !important',
  alignItems: 'center !important',
  '& .formHeader': {
    'display': 'flex',
    padding: '27px 172px 27px 172px',
    borderRadius: '24px 24px 0px 0px',
    background: 'var(--Primary, #398378)',
    alignItems: 'center !important',
    justifyContent: 'center',
  },
  '& .logo': {
    width: '176px',
    height: '72px'
  },
  '& .MuiContainer-maxWidthLg': {
    width: '100%',
    "@media only screen and (min-width: 1280px)": {
      minWidth: '100%'
    }
  },
  '& .inputeStyle': {
    borderRadius: '8px',
    border: '1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)',
    background: 'var(--Basic-White, #FFF)'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #398378 !important'
  },
  '& .button': {
    background: '#398378',
    padding: '16px',
    textTransform: 'none',
    width: '100% !important',
    color: '#fffff',
    fontWeight: '700',
    fontFamily: 'Ubuntu',
    fontSize: '16px',

  },
  '& .spaOwnerBtn': {
    background: '#E8F6F4',
    textTransform: 'none',
    width: '100%',
    color: '#398378',
    borderLeft: '4px solid #398378 !important',
    borderRadius: '8px',
    marginTop: '10px',
    cursor: 'pointer'
  },
  '& .btnSignUp': {
    color: '#398378',
    fontWeight: '700',
    fontFamily: 'Ubantu',
    fontSize: '16px'
  },
  '& .button .MuiButton-label': {
    color: 'white !important'
  },
  '& .spaOwnerBtn .MuiButton-label': {
    color: '398378 !important'
  },
  '& .txtRemember': {
    color: '#334155',
  },
  '& .MuiBox-root-19': {
    color: '#1B5950 !important'
  },
  '& .MuiBox-root-14': {
    color: '#334155 !important'
  },
  '& .PrivateSwitchBase-root-18': {
    paddingLeft: '0px'
  },
  '& .PrivateSwitchBase-root-27': {
    paddingLeft: '0px'
  },
  '& .PrivateSwitchBase-root-157': {
    paddingLeft: '0px'
  },
  '& .MuiCheckbox-colorSecondary': {
    paddingLeft: '0px'
  },
  '& .forgotPwdText': {
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "15px",
    alignSelf: 'flex-end',
    cursor: 'pointer',
    color: "#1B5950",
    fontWeight: "700",
    fontFamily: 'Ubuntu'
  },
  '& .mainView': {
    padding: '40px',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    background: 'var(--Neutrals-Cool-gray-50, #F8FAFC)',
    // display:'flex'
  },
  '& .testLabel': {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontWeight: '700',
    fontSize: '16px',
    color: '#334155',
    fontFamily: 'Ubuntu'
  },
  '& .testStar': {
    color: 'red',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '5px'
  },

  '& .remember': {
    fontSize: "14px",
    color: "#334155",
    fontWeight: "400",
    marginTop: "5px",
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  '& .PrivateSwitchBase-root-83': {
    paddingLeft: '0px !important'
  },
  '& .account': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 400,
    fontFamily: 'Ubuntu',
    cursor: 'pointer'

  },
  '& .signUp': {
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 'bold',
    fontFamily: 'Ubuntu',
    paddingLeft: '5px',
    cursor: 'pointer'

  },
  '& .infoIcon': {
    color: '#398378',
    fontSize: '16px',
    marginTop: '8px',
    paddingLeft: '2px'
  },
  '& .spaOwner': {
    width: ' 520px',
    textAlign: 'center',
    textTransform: 'none',
    padding: '16px',
    fontWeight: '700',
    fontSize: '16px',
    color: '#398378'
  },
  '& .arrow': {
    color: '#398378',
    fontSize: '16px',
    paddingRight: '20px'
  },
  '& .MuiSnackbarContent-root': {
    background: '#FEE2E2',
    color: '#DC2626',
    borderLeft: '4px solid #DC2626',
    borderRadius: '4px',
    width: '100%',
  },
  '& .MuiSnackbar-anchorOriginBottomCenter': {
    transform: 'translateX(0%)',
    position: 'inherit',
    justifyContent: 'start',
    marginTop: '20px'
  },
  '& .MuiPaper-root': {
    background: '#FEE2E2',
    left: '860px'
  },
  '& .PrivateSwitchBase-root-25': {
    paddingLeft: '0px'
  },
  '& .PrivateSwitchBase-root-30': {
    paddingLeft: '0px'
  },
  '& .checkedIcon': {
    fontSize: '12px',
    color: '#34D399'
  },
  '& .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded': {
    top: '140px !important',
    left: '870px !important'
  },
});

export const PhoneInputContainer = styled(Box)(({ theme }) => ({
  marginTop: '17px',
  height: '54px',
  '& .react-tel-input': {
    borderRadius: '8px',
    '&:focus-within': {
      border: `2px solid #398378`,
      boxShadow: 'none',
    },
    '& .form-control': {
      width: '98%',
      height: '100%',
      marginLeft: "10px",
      fontSize: theme.typography.body1.fontSize,
      fontFamily: 'Ubuntu',
      borderRadius: '8px',
      padding: '17px 17px 17px 60px',
      border: `1px solid ${theme.palette.grey[400]}`, 
      ...getRtlStyleValue(isArabic).phoneInputWidth,
      ...getRtlStyleValue(isArabic).phoneInputMargin,     
    },
    '& .flag-dropdown': {
      borderRadius: '5px 0 0 5px',
      backgroundColor: '#FFFFFF',
      width: '57px',      
    },
    '& .selected-flag': {
      marginLeft: '3px',
    },
  },
  '& .flag': {
    transform: 'scale(1.1)',
    transformOrigin: 'center',
    ...getRtlStyleValue(isArabic).flagStyles,
  },
  '& .flag .arrow': {
    transform: 'scale(1.36)',
    padding: "0px",
    marginLeft: "5px"
  },
  '&.error .react-tel-input': {
    '&:focus-within': {
      border: `2px solid ${theme.palette.error.main}`,
    },
  },
  '& .search': {
    marginRight: '20px',
  },
}));

const StyleSelectInput = styled(Select)(({ theme }) => ({
  '&.inputeStyle .MuiSelect-root': {
    color: 'gray',
  },
}));

const spaOwnerView = (state: any, txtFullName: any, star: any, setFullName: any, nameErrorMsg: any) => {
  const { t } = window as unknown as { t: Record<string, string> };

  return (
    <>
      {state.user_role == 'Customer' && <Box >
        <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
          <InputLabel className="testLabel">{t['signup-full-name']}{" "}</InputLabel>
          <InputLabel className="testStar">{star}</InputLabel>
        </Box>
        <TextField
          data-test-id="txtInputName"
          placeholder={t['signup-full-name-placeholder']}
          fullWidth={true}
          value={state.fullName}
          defaultValue={state.fullName}
          onChange={setFullName}
          variant="outlined"
          className="inputeStyle"
          error={state.nameError}
        />
        {state.nameError && <CustomTypography>
          <Typography className="txtErrorMsg">{t['signup-form-error-name']}</Typography>
        </CustomTypography>}
      </Box>}
    </>
  )
}
// Customizable Area End

import EmailAccountRegistrationController, {
  lang,
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  customerView = (isArabic: any) => {
    const { t } = window as unknown as { t: Record<string, string> };
    const {
      phoneTextAlign
    } = getRtlStyleValue(isArabic);

    return (
      <>
        {this.state.user_role == 'Customer' && <Box >
          <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
            <InputLabel className="testLabel">{t['signup-phone-number']}{" "}</InputLabel>
            <InputLabel className="testStar">{this.star}</InputLabel>
          </Box>
          <PhoneInputContainer className={this.getTernaryValue(this.state.phoneNumberError, 'error', '')}>
            <PhoneInput
              inputStyle={phoneTextAlign as React.CSSProperties}
              country={'qa'}
              value={this.state.phone}
              onChange={this.handlePhoneChange}
              inputProps={{
                'data-test-id': 'phone_input',
              }}
              enableSearch
              disabled={false}
              countryCodeEditable={false}

            />
          </PhoneInputContainer>
          {this.state.phoneNumberError && <CustomTypography>
            <Typography className="txtErrorMsg">{t['signup-form-error-phone']}</Typography>
          </CustomTypography>}
        </Box>}
        {this.state.user_role == 'Customer' && <Box >
          <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: "15px" }}>
            <InputLabel className="testLabel">{t['signup-gender']}{" "}</InputLabel>
            <InputLabel className="testStar">{this.star}</InputLabel>
          </Box>
          <StyleSelectInput
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.genderType}
            fullWidth
            variant="outlined"
            placeholder={t['signup-gender-placeholder']}
            className="inputeStyle"
            onChange={this.handleChange}
            defaultValue={this.state.genderType}
            error={this.state.typeError}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              MenuListProps: {
                style: {
                  padding: 0,
                },
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value={'Select'} disabled>{t['signup-gender-placeholder']}</MenuItem>
            <MenuItem value={'Male'}>{t['signup-gender-male']}</MenuItem>
            <MenuItem value={'Female'}>{t['signup-gender-female']}</MenuItem>
          </StyleSelectInput>
          {this.state.typeError && <CustomTypography>
            <Typography className="txtErrorMsg">{t['signup-form-error-gender']}</Typography>
          </CustomTypography>}
        </Box>}
      </>
    )
  }

  render() {
    const { t, dir, isArabic } = getTranslationConfig();

    return (
      <ThemeProvider theme={theme}>
        <div style={styles.paperContainer}>
          <Box
            position='relative'
            top={32}
            left={32}
            width='fit-content'
          >
            <LanguageSwitchDropdown forceUpdate={this.forceUpdate.bind(this)} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'space-between',
              padding: "30px 73px 0px 120px",
            }}
          >
            <Box width="45%" dir={dir}>
              <CustomTypography>
                <Typography className="heading">{t['signup-heading']}</Typography>
                <Typography className="description">{t['signup-description']}</Typography>
                {/*   */}
              </CustomTypography>
            </Box>
            <RegistrationFormView dir={dir}>
              <Box className="formHeader">
                <img src={`${headerLogoImg}`} className="logo" alt="Logo" />
              </Box>
              <Box className="mainView">
                <>
                  <CustomTypography>
                    <Typography className="title">{t['signup-form-heading']}</Typography>
                  </CustomTypography>
                  {this.state.isSuccess && this.state.apiMessage !== 'This email is already registered' &&
                    <Snackbar
                      open={true}
                      message={this.state.apiMessage}
                      key={'top' + 'right'}
                    />}
                  {
                    // Snackbar error is happening here for spa registration due to undefined data
                    spaOwnerView(this.state, this.txtFullName, this.star, this.setFullName, this.nameErrorMsg)
                  }
                  {
                    this.customerView(isArabic)
                  }

                  <Box >
                    <Box sx={{ display: 'flex', paddingTop: '15px', flexDirection: 'row', }}>
                      <InputLabel className="testLabel">{t['signup-form-email']}{" "}</InputLabel>
                      <InputLabel className="testStar">*</InputLabel>
                    </Box>
                    <TextField
                      fullWidth={true}
                      value={this.state.email}
                      defaultValue={this.state.email}
                      onChange={this.setEmail}
                      variant="outlined"
                      className="inputeStyle"
                      data-test-id="txtInputEmail"
                      placeholder={t['signup-form-email-placeholder']}
                      error={this.state.emailError}
                    />
                    <Snackbar
                      open={this.state.isSuccess && this.state.apiMessage === 'This email is already registered'}
                      message={this.state.apiMessage}
                      style={{ marginBottom: '10px' }}
                    />
                    {this.state.emailError &&
                      <CustomTypography>
                        <Typography className="txtErrorMsg">
                          {t['signup-form-error-email']}
                        </Typography>
                      </CustomTypography>}
                    {this.state.isSuccess && this.state.apiMessage == 'Email is already present or invalid' && <Snackbar
                      open={true}
                      // onClose={handleClose}
                      message={this.state.apiMessage}
                      key={'top' + 'right'}
                    />}
                  </Box>
                  <Box >
                    <Box sx={{ flexDirection: 'row', display: 'flex', paddingTop: '15px' }}>
                      <InputLabel className="testLabel">{t['signup-form-password']}{"  "}</InputLabel>
                      <InputLabel className="testStar">{this.star}</InputLabel>
                      <ClickAwayListener data-test-id='click-away' onClickAway={() => this.handleClose()}>
                        <InfoOutlinedIcon
                          data-test-id='pass-info-icon'
                          className="infoIcon"
                          aria-describedby={'pop-over'}
                          style={{ cursor: 'pointer' }}
                          onClick={
                            (event: any) => {
                              this.handlePopover(event);
                            }} />
                      </ClickAwayListener>
                      <Popper
                        id={'pop-over'}
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        placement='right-start'
                        style={{
                          borderRadius: '8px',
                          left: '16px',
                          top: '-8px'
                        }}
                      >
                        <Box
                          p={2}
                          dir={dir}
                          style={{
                            background: '#D9EEEB',
                            borderRadius: '8px',
                            marginLeft: '8px',
                            marginTop: '-4px'
                          }}>
                          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }} p={0.2}><CheckCircleIcon style={styles.iconStyle} /> <CustomTypography><Typography className="testStyle">{t['signup-form-pwd-rule1']}</Typography></CustomTypography></Box>
                          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }} p={0.2}><CheckCircleIcon style={styles.iconStyle} /><CustomTypography><Typography className="testStyle">{t['signup-form-pwd-rule2']}</Typography></CustomTypography></Box>
                          <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }} p={0.2}><CheckCircleIcon style={styles.iconStyle} /><CustomTypography><Typography className="testStyle">{t['signup-form-pwd-rule3']}</Typography></CustomTypography></Box>
                        </Box>
                      </Popper>
                    </Box>
                    <TextField
                      data-test-id="txtInputPassword"
                      fullWidth={true}
                      value={this.state.password}
                      defaultValue={this.state.password}
                      onChange={this.setPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.getPwdEyeIcon(this.state.enablePasswordField)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={this.state.enablePasswordField ? "password" : "text"}
                      placeholder={t['signup-form-password-placeholder']}
                      variant="outlined"
                      error={this.state.pwdError}
                    />
                    {this.state.pwdError ? <CustomTypography>
                      <Typography className="txtErrorMsg">{this.state.pwdMsg == '' ? t['signup-form-error-password'] : this.state.pwdMsg}</Typography>
                    </CustomTypography> : <CustomTypography>
                      <Typography className="txtMsg">{this.state.pwdMsg}</Typography>
                    </CustomTypography>}
                  </Box>
                  <Box >
                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                      <InputLabel className="testLabel">{t['signup-form-confirm-password']}{"  "}</InputLabel>
                      <InputLabel className="testStar">{this.star}</InputLabel>
                    </Box>
                    <TextField
                      data-test-id="txtInputConfirmPassword"
                      type={this.state.enableReTypePasswordField ? "password" : "text"}
                      fullWidth={true}
                      value={this.state.confirmPassword}
                      defaultValue={this.state.confirmPassword}
                      onChange={this.setConfirmPassword}
                      placeholder={t['signup-form-confirm-password-placeholder']}
                      variant="outlined"
                      error={this.state.confirmPasswordError}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {this.getPwdEyeIcon(this.state.enableReTypePasswordField)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {this.state.confirmPasswordError && (
                      <CustomTypography>
                        <Typography className="txtErrorMsg">
                          {this.state.confirmPassword === ""
                            ? t['signup-form-confirm-password']
                            : this.confirmPwdErrorMsg}
                        </Typography>
                      </CustomTypography>
                    )}
                  </Box>
                  <Box
                    className="remember"
                  >
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={this.setRememberMe}
                      checked={this.state.checked}
                      className="txtRemember"
                    />
                    <CustomTypography>
                      <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                        <Typography className="readAndAgree">{t['signup-form-terms']}</Typography>
                        <Typography className="termsAndCond" onClick={this.goToTermsAndCondition}>{t['signup-form-terms-link']}</Typography>
                      </Box>
                    </CustomTypography>
                  </Box>
                </>
                {this.state.user_role !== 'Customer' &&
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box
                      data-test-id={"btnForgotPassword"}
                      className="forgotPwdText"
                      onClick={() => this.goToForgotPassword()}

                    >
                      {t['signup-form-forogot-password']}
                    </Box>
                  </Box>}
                <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px 0px",
                      width: '100%'
                    }}
                  >
                    <Button
                      data-test-id={"btnEmailSignUp"}
                      className="button"
                      fullWidth={true}
                      onClick={this.createCustomerAccount}
                    >
                      {t['signup-form-submit-btn']}
                    </Button>
                  </Box>
                  {this.state.user_role == 'Customer' && <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      // padding: "10px 0px",
                      justifyContent: 'space-between'
                    }}
                    className="spaOwnerBtn"
                    onClick={this.handleUserRole}
                  >
                    <Button
                      data-test-id={"btnEmailLogIn"}
                      className="spaOwner"
                      onClick={this.handleUserRole}
                    >
                      {t['signup-form-spa-owner-register']}
                    </Button>
                    {isArabic ? <ArrowBackwardIos className="arrow" /> : <ArrowForwardIos className="arrow" />}
                  </Box>}
                </Box>
                <CustomTypography>
                  <Box pt={2} pb={2} sx={{ flexDirection: 'row', display: 'flex', }} onClick={this.gotoLogin}>
                    <Typography className="account"> {t['signup-form-have-account']} </Typography><Typography className="signUp"> {t['signup-form-login']}</Typography>
                  </Box>
                </CustomTypography>
              </Box>
            </RegistrationFormView>
          </Box>
        </div>
      </ThemeProvider>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    this.getUserRole();
    // Customizable Area End
  }
}

