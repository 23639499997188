import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { Country, State, City, ICountry, IState, ICity } from 'country-state-city';
import React from 'react';
import DateObject from "react-date-object";
import { getFormDataFromPayload } from '../../../blocks/utilities/src/handle-form';
import { incrementRandomNumber } from '../../../blocks/utilities/src/handle-calc';
import { clockClosed, clockOpened, clockWait, profileCover } from './assets';
import { Catalogue } from "./EditServiceController.web";
import {
  addZeroToTime,
  calculateSlots,
} from "../../../components/src/datesRangeHelper";
import { UpdateType } from './SpaOwnerSettings.web';
import { parsePhoneNumber, CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { applyUserAction, findCurrByCode, getIsRestricted, getTransDuration, getTranslationConfig } from '../../../components/src/helpers';
import moment from "moment";
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import { LocationAddress, Places, defaultLocation } from '../../settings1/src/Settings1Controller';
import { LatLng } from '../../../components/src/MapLocationAdv';
import { createBrowserHistory } from "history";

// Fix object for duration picker minutes steps to show the range with each step as 10 minutes
const durationConversion = {
  33: '00',
  34: '10',
  0o1: '10',
  0o2: '20',
  0o3: '30',
  0o4: '40',
  31: '40',
  32: '50'
};

export const getNavigationMessage = (route: string, props: any): Message => {
  const isSubscr = route === 'Subscriptionbilling';
  const subscrNavMessage = isSubscr && MessageEnum.NavigationSubscriptions

  const message = new Message(getName(subscrNavMessage || MessageEnum.NavigationMessage));

  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  isSubscr && message.addData(getName(MessageEnum.NavigationPayLoadMessage), { isUpdate: true });

  return message;
}

export const callApi = (payload: any, runEngine: any) => {
  const { contentType, method, endPoint, body, headers } = payload;
  const header = {
    "Content-Type": contentType,
    ...headers
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

type DurationPickerObjInit = {
  hours?: number;
  minutes?: number;
  marker?: string;
};

type DurationPickerObjConv = {
  hours?: string;
  minutes?: string;
  marker?: string;
};

export type SubCategory = {
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: number,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
};

export type CategorySlot = {
  id: number;
  catalogueId: number;
  serviceName: string;
  categoryName: string;
  capacity: number;
  date: string[];
  slots: string[];
  slotData: {
    id: number;
    duration: string;
  };
};

export type PopoverAnchor = 'anchorElLicence' | 'anchorElCertificate' | 'anchorElOtherFiles';

export type FileWithId = (File & { id?: string });
export type ImgObj = {
  id: number;
  url: string;
};

type APITypesKeys = 'licenses' | 'certificates' | 'carousal_images';

type TimeSlot = {
  start_time: string;
  end_time: string;
  date: string[];
  status: string;
  same_timing: boolean;
};

const zeroes = '00';

type Nullable<T> = T | null | undefined;

export interface UserDetails {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    spa_timings: TimeSlot[];
    gender: string;
    phone_number: number | undefined;
    email: string;
    country_code: number | undefined;
    about: string;
    about_us: string;
    currency: string | null;
    profile_data: {
      attributes: {
        address: string,
        photo: string,
        country: string,
        city: string,
        postal_code: string
        bio: string
      }
    }
    profile_photo: {
      url: ""
    }
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string;
    rating: string;
    licenses: any;
    certificates: any;
    carousal_images: {
      id: number,
      url: string
    }[]
  }
}

export const initialUserDetails: UserDetails = {
  id: "",
  attributes: {
    full_name: "",
    open_timing: "",
    spa_timings: [{
      start_time: '',
      end_time: '',
      date: [''],
      status: '',
      same_timing: false
    }],
    gender: "",
    phone_number: 0,
    email: "",
    country_code: 0,
    about: "",
    about_us: "",
    currency: null,
    profile_data: {
      attributes: {
        address: "",
        photo: "",
        country: "",
        city: "",
        postal_code: "",
        bio: ""
      },
    },
    profile_photo: {
      url: ""
    },
    services: [],
    reviews: [],
    image_url: "",
    rating: "",
    licenses: null,
    certificates: null,
    carousal_images: [],
  },
};
export const spaTimingSlots = [
  {
    labelEn: "10:00 am - 6:00 pm",
    labelAr: "10:00 ص - 6:00 م",
  },
  {
    labelEn: "9:30 am - 7:00 pm",
    labelAr: "9:30 ص - 7:00 م",
  },
  {
    labelEn: "10:00 am - 5:30 pm",
    labelAr: "10:00 ص - 5:30 م",
  },
  {
    labelEn: "10:00 am - 7:00 pm",
    labelAr: "10:00 ص - 7:00 م",
  },
];

type StateUpdateMap = {
  [key in S['buttonMode']]: Partial<S>;
};

export type CategorySlotsError = {
  date: string;
  duration: string;
  availabilities: {
    timeSlots: string
  }[];
  slotData: {
    duration: string;
  };
};

export interface Currency {
  name: string;
  code: string;
};

const initDurationPicker = {
  durationFrom: { hours: zeroes, minutes: zeroes, marker: "am" },
  durationTo: { hours: zeroes, minutes: zeroes, marker: "pm" },
  hasStarted: false,
};

export interface Notification {
  id: string;
  type: string;
  attributes: {
    id: number;
    headings: string;
    contents: string;
    is_read: boolean | null;
    notification_type: string;
    service_provider: {
      id: number;
      name: string | null;
      profile_photo: string;
    };
  };
}

export type ModalConfig = {
  title: string,
  message: string,
  confirmText: string,
  cancelText: string,
  onConfirm: () => void,
};

interface CurrentSlot {
  availability_id: number;
  dates: string[]
}

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openCancelModal?: boolean;
  setOpenCancelModal?: (arg: boolean) => void;
  setBookedID?: (id: number) => void;
  openConfirmationModal?: boolean;
  setOpenConfirmationModal?: (arg: boolean) => void;
  openUpdateModal?: boolean;
  setOpenUpdateModal?: (arg: boolean) => void;
  openLogoutModal?: boolean;
  setOpenLogoutModal?: (arg: boolean) => void;
  openNotificationModal?: boolean;
  setOpenNotificationModal?: (arg: boolean) => void;
  modalConfig?: ModalConfig,
  updateType?: UpdateType;
  notification?: Notification;
  getLanguageBasedStyle?: <T>(isArabic: boolean | undefined, arabicValue: T, defaultValue: T) => T;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  buttonIndex: number,
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  services: Catalogue[];
  datesRange: any[][];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  avatarImage: any;
  upldAvatarLink: string | ArrayBuffer | null,
  backgroundImgLink: any;
  fullname: string;
  spaAddress: string;
  isOpen: any;
  updateProfilePopup: boolean;
  anchorEl: any;
  anchorSlots: any;
  anchorElLicence: any[];
  anchorElCertificate: any[];
  anchorElOtherFiles: any[];
  anchorElSlots: HTMLButtonElement | null;
  currentSlotIndex: number;
  uploadImg: any;
  isSpaNameReviewPopup: boolean;
  isLicenseCertificationPopup: boolean;
  isSpaTimingPopup: boolean;
  isEditLocationPopup: boolean;
  fileError: any;
  certificateError: string;
  businessLicenseError: string;
  businessLicenseImage: (File & { id: string })[],
  certificateImage: File[],
  otherImages: File[],
  addDisabled: boolean,
  files: any;
  countries: ICountry[],
  states: IState[],
  cities: ICity[],
  countryCode: string,
  country: string;
  city: string;
  stateValue: string;
  spaName: string;
  spaNameError: boolean;
  address: string;
  addressError: boolean;
  webLink: string;
  countryError: boolean;
  stateError: boolean;
  cityError: boolean;
  venueCarouselIndex: any;
  updatedSpaTiming: string;
  originalSpaTiming: string;
  spaTodayStatus: string;
  uploadProgress: number;
  progressBars: number[];
  uploading?: any;
  certificateUpload?: any;
  newLicenseUploadFiles: File[]
  progress: number;
  addPrefferTiming: boolean;
  carouselLoading: boolean;
  loading: boolean;
  aboutUs: string | undefined | null;
  sameTimeChecked: boolean;
  durationPicker: {
    durationFrom: DurationPickerObjConv,
    durationTo: DurationPickerObjConv,
    hasStarted: boolean
  },
  isDurationFrom: boolean;
  showCalendarPopUp: boolean;
  openCalendar: boolean;
  openingDates: string;
  openingUIDates: string;
  openingDatesList: string[];
  apiError: boolean,
  idsToRemoveFiles: {
    licenses: string[],
    certificates: string[],
    carousal_images: string[]
  }
  isCustomer: boolean
  isEditMode: boolean
  userDetails: UserDetails;
  editedDetails: UserDetails['attributes'];
  value: string | undefined;
  buttonMode: string;
  openCancelModal?: boolean;
  openConfirmationModal: boolean;
  openUpdateModal: boolean;
  openLogoutModal:boolean;
  openNotificationModal: boolean;
  cancelModalServiceName: string | undefined;
  bookedID: number | undefined;
  bookedBookings: any[];
  completedBookings: any[];
  cancelledBookings: any[];
  count: {
    booked: number;
    cancelled: number;
    completed: number;
  };
  modalConfig: ModalConfig,
  updateType?: UpdateType;
  newValue: string
  confirmValue: string
  showSnack: boolean;
  snackMessage: string;
  emailError: string | null;
  emailConfirmError: string | null;
  passwordError: string | null;
  phoneError: string | null;
  editMessage: string;

  timeSlots: string[][];
  selectedSlotIndexes: {
    id: number,
    indexes: number[]
  }[];
  slotDates: any;
  anchorDuration: HTMLElement | null,
  selectedServiceIndex: number,
  currentPopperIndex: number;
  currencyList: Currency[];
  displayCurrencyList: Currency[];
  currencyUpdated: boolean;
  anchorInfoEl: any;
  openInfoPopover: boolean;
  phoneNumberError: boolean;
  locationAddress: LocationAddress,
  places: Places,
  coords: LatLng | null,
  newEmail: string,
  activeButton: string,
  notifications: Notification[]
  selectedNotification: Notification,
  userCurrentSubscription: null | number,
  showRestrictedPrompt: boolean;
  notificationsLoading: boolean,
  notificationErrorMessage: string;
  userAction?: boolean;
  openDrop: boolean;
  countryOpen: boolean;
  stateOpen: boolean;
  cityOpen: boolean;
  spaOpen: boolean;
  openComponent: string;
  reorderedDates: string[];
  currentSlot: CurrentSlot | null;
  nextPath:string | null
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  currency = '';
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  updateProfileApiCallId: any;
  userSpaNameAPICallId: string = "";
  userAttr: any;
  apiGetUserDetailsCallId: string = ""
  deleteDocumentApiCallId: string = "";
  apiGetUserCurrentSubscriptionId = "";
  licenseTxt = 'License';
  certTxt = 'Certificates';
  otherFilesTxt = 'Other files';
  apiGetCustomerDetailsCallId = ""
  deleteUserApiCallId = ""
  apiUpdateBookingCallId = ""
  apiGetCataloguesCallId = "";
  apiAvailabilityCallId = "";
  spaOwnerSettingsApiCallId = "";
  apiGetNotificationsCallId = "";
  deleteNotificationApiCallId = "";
  private _isMounted: boolean = false;
  unregisterBackHandler: (() => void) | null;
  history: any;
  unblock: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    (async () => {
      this.currency = await getStorageData('currency');
    })();

    this.history = createBrowserHistory();
    this.unregisterBackHandler = null;

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      firstName: "",
      buttonIndex: 0,
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      services: [],
      datesRange: Array.from(new Array(50), () => []),
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",
      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      backgroundImgLink: null,
      avatarImage: null,
      upldAvatarLink: null,
      fullname: "",
      spaAddress: "",
      isOpen: null,
      updateProfilePopup: false,
      anchorEl: false,
      anchorSlots: false,
      anchorElLicence: [],
      anchorElCertificate: [],
      anchorElOtherFiles: [],
      anchorElSlots: null,
      currentSlotIndex: 0,
      uploadImg: null,
      isSpaNameReviewPopup: false,
      isLicenseCertificationPopup: false,
      isSpaTimingPopup: false,
      isEditLocationPopup: false,
      fileError: "",
      certificateError: "",
      businessLicenseError: "",
      businessLicenseImage: [],
      certificateImage: [],
      otherImages: [],
      addDisabled: false,
      files: [],
      countries: Country.getAllCountries(),
      states: [],
      cities: [],
      countryCode: "",
      country: "",
      city: "",
      stateValue: "",
      spaNameError: false,
      spaName: '',
      address: '',
      addressError: false,
      webLink: '',
      countryError: false,
      stateError: false,
      cityError: false,
      venueCarouselIndex: 0,
      updatedSpaTiming: '',
      originalSpaTiming: '',
      spaTodayStatus: 'OPEN',
      uploadProgress: 0,
      uploading: false,
      certificateUpload: false,
      newLicenseUploadFiles: [],
      progress: 0,
      addPrefferTiming: false,
      loading: false,
      notificationsLoading: true,
      notificationErrorMessage: '',
      carouselLoading: false,
      aboutUs: undefined,
      sameTimeChecked: false,
      durationPicker: initDurationPicker,
      isDurationFrom: true,
      showCalendarPopUp: true,
      openCalendar: false,
      openingDates: '',
      openingUIDates: '',
      openingDatesList: [],
      apiError: false,
      idsToRemoveFiles: {
        licenses: [],
        certificates: [],
        carousal_images: []
      },
      progressBars: [],
      isCustomer: false,
      isEditMode: false,
      userDetails: { ...initialUserDetails },
      editedDetails: { ...initialUserDetails.attributes },
      value: undefined,
      buttonMode: 'completed',
      openCancelModal: false,
      openConfirmationModal: false,
      openUpdateModal: false,
      openLogoutModal:false,
      openNotificationModal: false,
      cancelModalServiceName: "",
      bookedID: 0,
      bookedBookings: [],
      completedBookings: [],
      cancelledBookings: [],
      count: { booked: 0, cancelled: 0, completed: 0 },
      modalConfig: {
        title: '',
        message: '',
        confirmText: '',
        cancelText: '',
        onConfirm: () => { },
      },
      updateType: undefined,
      newValue: "",
      confirmValue: "",
      showSnack: false,
      snackMessage: '',
      emailError: null,
      emailConfirmError: null,
      passwordError: null,
      phoneError: null,
      editMessage: "",

      timeSlots: [],
      selectedSlotIndexes: [],
      slotDates: [],
      anchorDuration: null,
      selectedServiceIndex: -1,
      currentPopperIndex: 0,
      currencyList: [],
      displayCurrencyList: [],
      currencyUpdated: false,
      anchorInfoEl: null,
      openInfoPopover: false,
      phoneNumberError: false,
      locationAddress: defaultLocation,
      places: [],
      coords: null,
      newEmail: "",
      activeButton: "all",
      notifications: [],
      selectedNotification: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          headings: "",
          contents: "",
          is_read: null,
          notification_type: "",
          service_provider: {
            id: 0,
            name: null,
            profile_photo: ""
          }
        }
      },
      userCurrentSubscription: null,
      showRestrictedPrompt: false,
      openDrop: false,
      countryOpen: false,
      stateOpen: false,
      cityOpen: false,
      spaOpen: false,
      openComponent: "",
      reorderedDates: [],
      currentSlot: null,
      nextPath:null
    };

    this.handleEdit = this.handleEdit.bind(this);

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const navigationPayloadMessage = message.getData(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    if (navigationPayloadMessage) {
      const { locationAddress } = navigationPayloadMessage;
      if (locationAddress) {
        this.handleEditSPALocation({
          country: locationAddress.country,
          state: locationAddress.state,
          city: locationAddress.city,
          address: locationAddress.address
        })
        this.setState({ locationAddress, isEditLocationPopup: true });
      }

      this.setState({ editMessage: navigationPayloadMessage.editMessage });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // Do not use errorReponse here to avoid unnecessary error handling for booking cards images that are not found
      if (!(responseJson?.data || responseJson?.message)) {
        const errorMessage = this.getErrorMessage(errorReponse, responseJson)
        this.handleErrorMessage(errorMessage);
        this.setState({ loading: false, notificationsLoading: false });
        return
      }

      if (this.getCombinedLogicalValue(!!responseJson.data, apiRequestCallId === this.apiGetCustomerDetailsCallId)) {
        const { data } = responseJson;
        const { booking_data, count } = data;

        const stateUpdateMap: Partial<Record<S['buttonMode'], Partial<S>>> = {
          completed: { completedBookings: booking_data, count },
          booked: { bookedBookings: booking_data },
          cancelled: { cancelledBookings: booking_data }
        };

        const mode = this.state.buttonMode;
        const update = stateUpdateMap[mode];

        this.setState(prevState => ({
          ...prevState,
          ...update
        }));
      }

      if (apiRequestCallId === this.apiUpdateBookingCallId) {
        this.onCancelBooking(responseJson.data?.id);
      }

      switch (apiRequestCallId) {
        case this.apiGetUserDetailsCallId:
          this.handleUserProfileInformation(responseJson)
          break;
        case this.apiGetCataloguesCallId:
          this.handleCataloguesInfo(responseJson);
          break
        case this.updateProfileApiCallId:
          this.handleProfileUpdateResponse(responseJson)
          break;
        case this.userSpaNameAPICallId:
          this.handleSpaNameUpdateApiResponse(responseJson)
          break;
        case this.deleteDocumentApiCallId:
          this.handleDeleteDocumentApiResponse(responseJson);
          break;
        case this.apiAvailabilityCallId:
          this.handleSuccessUpdateAvailability();
          break;
        case this.spaOwnerSettingsApiCallId:
          this.handleSuccessUpdateSettings();
          break;
        case this.apiGetNotificationsCallId:
          this.handleNotificationsApiResponse(responseJson);
          break;
        case this.apiGetUserCurrentSubscriptionId:
          this.handleSubscriptionApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  
  async componentDidMount() {
    this._isMounted = true;
    this.getSpaUserProfile()
    this.getCalagoues();
    this.getCutomerBookingDetails(this.state.buttonMode)
    this.getNotifications()

    const userRole = await getStorageData('userRole');
    if (this._isMounted) {
      this.setState({ isCustomer: userRole === 'Customer' });
    }

    const phoneNumber = this.state.userDetails?.attributes?.phone_number;
    if (phoneNumber) {
      this.setState({
        phoneNumber: phoneNumber.toString(),
        phoneNumberError: false
      });
    }

  }

  async componentWillUnmount(): Promise<void> {
    this._isMounted = false;
  }


  getErrorMessage(errResponse: any, responseJson: any) {
    if (errResponse) {
      return errResponse
    } else if (responseJson && responseJson.error && responseJson.error.length > 0) {
      return responseJson.error[0].message;
    } else {
      return '';
    }
  }

  handleErrorMessage(errorMessage: string) {
    if (errorMessage === "No booked slots found.") {
      this.setState({
        bookedBookings: [],
        completedBookings: [],
        cancelledBookings: [],
        count: { booked: 0, completed: 0, cancelled: 0 },
        apiError: false
      });
    } else {
      this.parseApiCatchErrorResponse(errorMessage);
      this.setState({ apiError: true });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.buttonMode !== this.state.buttonMode) {
      this.getCutomerBookingDetails(this.state.buttonMode);
    }
    if (prevState.services !== this.state.services ||
      prevState.buttonIndex !== this.state.buttonIndex) {
      const currentSlots =
        this.state.services[this.state.buttonIndex]?.attributes.availabilities;

      if (currentSlots && Array.isArray(currentSlots)) {
        this.setState({
          currentSlot: currentSlots[0]
        }, () => {
          this.updateReorderedSlotDates(currentSlots);
        });
      }
    }
  }
  setTimeSlots = (timeSlots: string[], index: number) => {
    const timeSlotsCopy = this.state.timeSlots;
    timeSlotsCopy[index] = timeSlots;
    this.setState({ timeSlots: timeSlotsCopy });
  };

  generateTimeSlots = (
    slotDuration: string,
    index: number,
    timeSlots?: string[]
  ) => {
    const timeStrings = calculateSlots(
      "10:00 am - 06:00 pm",
      slotDuration,
      { timeSlots }
    ).map((timeObject) => timeObject.time);
    this.setTimeSlots(timeStrings, index);
    return timeStrings;
  };

  setSelectedSlotIndexes = (indexes: number[], durationId: number) => {
    const copySelectedIndexes = this.state.selectedSlotIndexes
    const selectedSlots = copySelectedIndexes.find((slots) => slots.id === durationId) || { id: durationId, indexes };

    const resSlot = { ...selectedSlots, indexes };
    const indexOfItemInArray = copySelectedIndexes.findIndex(slot => slot.id === durationId);

    if (~indexOfItemInArray) {
      copySelectedIndexes[indexOfItemInArray] = resSlot;
      this.setState({ selectedSlotIndexes: copySelectedIndexes });
    } else {
      this.setState({ selectedSlotIndexes: [...copySelectedIndexes, resSlot] });
    }
  };

  pushOrRemoveIndex = (targetArray: any[], item: string | number) => {
    const slotIdentIndex = targetArray.indexOf(item as never);
    const targetListCopy = [...targetArray];
    ~slotIdentIndex
      ? targetListCopy.splice(slotIdentIndex, 1)
      : targetListCopy.push(item as never);
    return targetListCopy;
  };

  setSlotDates = (slotDates: any, index: number) => {
    const copySlotDates = this.state.slotDates;
    copySlotDates[index] = slotDates;
    this.setState({ slotDates: copySlotDates });
  };

  getInputError = (isTouched?: boolean, error?: string | boolean) =>
    !!(isTouched && error);

  getErrorBorder = (isError: boolean) =>
    isError ? { border: "1px solid #DC2626" } : {};

  getFullDate = (multiDateObj: any) => {
    const { year, month, day } = multiDateObj;
    return `${year}-${addZeroToTime(Number(month))}-${addZeroToTime(
      Number(day)
    )}`;
  };

  convertDateToIso = (date: Date) => date.toISOString().split("T")[0];

  setDates = (dates: DateObject[][], callback: (dates: string[]) => void) => {
    const oneDayMs = 86400000;
    const allDatesRange: string[] = [];
    Array.isArray(dates) &&
      dates.forEach((dateObject: any) => {
        if (dateObject.length) {
          const beginDate = new Date(this.getFullDate(dateObject[0]));

          allDatesRange.push(this.convertDateToIso(beginDate));

          if (dateObject.length - 1) {
            const endDate = new Date(this.getFullDate(dateObject[1]));
            const datesRange =
              ((endDate as any) - (beginDate as any) + oneDayMs) / oneDayMs;

            Array.from(Array(datesRange)).forEach((_, index) => {
              const beginDataCopy = new Date(beginDate);
              beginDataCopy.setDate(beginDataCopy.getDate() + index);
              allDatesRange.push(this.convertDateToIso(beginDataCopy));
            });
          }
        }
      });

    const openingDatesList = this.getUniqArray(allDatesRange).sort();
    callback(openingDatesList);
  };

  getAutocompleteStyle = (isError: boolean) => ({
    padding: "12px 16px",
    borderRadius: "8px",
    minHeight: "56px",
    ...(isError && { border: "1px solid red" }),
  });

  getHighlightSlotStyle = (slotIndex: number, durationId: number) =>
    this.state.selectedSlotIndexes.find(slots => slots.id === durationId)?.indexes.includes(slotIndex)
      ? {
        backgroundColor: "#398378ff",
        color: "#ffffff",
      }
      : {};

  handleOpenDurationPopOver = (event: React.MouseEvent<HTMLElement>, index: number) =>
    this.setState({ anchorDuration: event.currentTarget, selectedServiceIndex: index });

  setAmPmColor = (markerExp: string, durationFromExp: boolean) => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    const isFrom = durationFromExp && durationFrom.marker === markerExp;
    const isTo = !durationFromExp && durationTo.marker === markerExp;
    return isFrom || isTo ? "#398378" : "#CBD5E1";
  };

  setTimeMarker = (marker: string, durationFrom: boolean) => {
    const durPicker = this.state.durationPicker;
    const resDuration = durationFrom
      ? {
        durationFrom: {
          ...durPicker.durationFrom,
          marker,
        },
      }
      : {
        durationTo: {
          ...durPicker.durationTo,
          marker,
        },
      };

    this.setState({
      durationPicker: {
        ...durPicker,
        ...resDuration,
      },
    });
  };

  handleTimeSlots = () => {
    const timeSlotsCopy = this.state.timeSlots;
    const index = this.state.selectedServiceIndex
    timeSlotsCopy[index] = [...timeSlotsCopy[index], this.getDuration()];
    this.setState({
      timeSlots: timeSlotsCopy,
      anchorDuration: null,
      durationPicker: initDurationPicker,
      isDurationFrom: true,
      selectedServiceIndex: -1
    });
  };

  handleCloseDurationPopOver = () => this.setState({ anchorDuration: null, selectedServiceIndex: -1 });

  handleSuccessUpdateAvailability() {
    this.handleNavigationToBasicInformation("SpaUserProfile");
  }

  handleSuccessUpdateSettings() {
    this.setState({ currencyUpdated: true })
  }

  getSpaUserProfile = async () => {
    this.setState({ loading: true, carouselLoading: true });

    const user_id = await getStorageData("user_id")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    this.apiGetUserDetailsCallId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: `${configJSON.userDetailsAPIEndPoint}?id=${user_id}`,
        headers: headers
      },
      runEngine
    )

  }

  getCalagoues = async () => {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    this.apiGetCataloguesCallId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: configJSON.userCatalogueAPIEndPoint,
        headers: headers,
      },
      runEngine
    );
  };

  async getUserCurrentSubscription() {
    this.setState({ loading: true });
    const authToken = await getStorageData("authToken");

    this.apiGetUserCurrentSubscriptionId = callApi(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.currentSubscriptionApiUrl,
        headers: { token: authToken },
      },
      runEngine
    );
  }

  handleSubscriptionApiResponse(responseJson: any) {
    const activeSubscr = responseJson.data.find((subscription: any) => subscription.status.toLowerCase() === 'active');
    this.setState({ userCurrentSubscription: activeSubscr?.plan_id })
  }

  handleUserProfileInformation = (responseJson: any) => {
    if (responseJson.data) {
      const profilePhoto = responseJson.data.attributes.profile_photo;
      const avatarImageUrl = profilePhoto ? profilePhoto.url : '';
      const profileData = responseJson.data.attributes.profile_data;
      const { country, postal_code, city, address } = profileData?.attributes ?? {};

      this.setState({
        userDetails: responseJson.data,
        avatarImage: avatarImageUrl,
        businessLicenseImage: responseJson.data.attributes.licenses || [],
        certificateImage: responseJson.data.attributes.certificates || [],
        otherImages: responseJson.data.attributes.other_files || [],
      });
      setStorageData('avatarImageUrl', this.state.avatarImage);
      this.setState({
        carouselLoading: false
      });
      this.handleEditSPALocation({
        country: this.state.locationAddress.country || country,
        state: this.state.locationAddress.state || postal_code,
        city: this.state.locationAddress.city || city,
        address: this.state.locationAddress.address || address,
      })
    }
  }

  handleCataloguesInfo = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        services: responseJson.data,
        loading: false,
      });
    }
  };

  handleUpdateProfilePopup = () => {
    this.setState({
      updateProfilePopup: !this.state.updateProfilePopup
    })
  }

  onSuccessMessageClose = () => this.setState({ editMessage: "" })

  handleProfilePicUpload = async (event: any) => {
    const user_id = await getStorageData("user_id");
    const imageFiles = event.target.files[0];

    const validImageTypes = ['image/jpeg', 'image/png'];
    if (!validImageTypes.includes(imageFiles.type)) {
      alert("Please upload a valid image file (JPG or PNG).");
      return;
    }

    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setState({ avatarImage: fileReader.result, anchorEl: null });
    });
    fileReader.readAsDataURL(imageFiles);
    const APIPayload = {
      'account[profile_photo]': imageFiles,
      'account[profile][account_id]': user_id
    };

    await this.updatProfileApiCall(getFormDataFromPayload(APIPayload));
  }

  handleUploadImage = async (imageFiles: FileList | null | undefined) => {
    if (!imageFiles) return;

    const validFormats = ["image/png", "image/jpeg", "image/jpg"];
    const filteredFiles = Array.from(imageFiles).filter(file => validFormats.includes(file.type));

    if (filteredFiles.length === 0) {
      alert("Only PNG, JPG formats are allowed.");
      return;
    }

    const user_id = await getStorageData("user_id");

    const APIPayload = {
      'account[carousal_images][]': filteredFiles,
      'account[profile][account_id]': user_id
    };

    this.updatProfileApiCall(getFormDataFromPayload(APIPayload));
    this.setState({ carouselLoading: true });
  };
  handleRemoveProfilePic = async () => {
    const user_id = await getStorageData("user_id")
    this.setState({ avatarImage: null, anchorEl: null });
    const APIPayload = {
      'remove_profile_image': 'true',
      'account[profile][account_id]': user_id
    }
    this.updatProfileApiCall(getFormDataFromPayload(APIPayload))
  }

  handleSpaNameUpdateApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ updateProfilePopup: false, isSpaNameReviewPopup: true })
    }
  }
  handleProfileUpdateResponse = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({
        uploadImg: false,
        isEditLocationPopup: false
      });
      this.getSpaUserProfile();
    }
  }
  handleDeleteDocumentApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        anchorElLicence: [],
        anchorElCertificate: [],
      })
      this.getSpaUserProfile();
    }
  }

  handleNavigationToEditService = () => {
    if (this.handleUserAction()) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEditSpaService)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  };

  updatProfileApiCall = async (formData: any) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken,
    };
    this.updateProfileApiCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: "POST",
        endPoint: configJSON.updateProfilePicEndPoint,
        headers: headers,
        body: formData
      },
      runEngine
    )
  }

  handleSpaReviewPopupClose = () => {
    this.setState({ isSpaNameReviewPopup: false })
  }
  handleNavigationToBasicInformation = (route: string, callback?: () => void | boolean) => {
    if (callback) {
      callback() && this.send(getNavigationMessage(route, this.props));
    } else {
      this.send(getNavigationMessage(route, this.props))
    }
  }

  handleCloseLicenseUploadPopup = () => {
    this.setState({
      idsToRemoveFiles: {
        licenses: [],
        certificates: [],
        carousal_images: []
      },
      progressBars: [],
      isLicenseCertificationPopup: false
    });

    this.setInitUserFiles();
  }

  onSliderClick(event: React.MouseEvent<HTMLElement>, backBtnRef: HTMLElement, nextBtnRef: HTMLElement) {
    const isLeftClick = event.screenX < window.innerWidth / 2;
    const targetRef = isLeftClick ? backBtnRef : nextBtnRef;
    targetRef.click();

    const carouslImagesLength = this.state.userDetails?.attributes.carousal_images.length;

    const currentIndex = this.state.venueCarouselIndex;
    const prevSlide = currentIndex > 0 && currentIndex - 1;
    const nextSlide = currentIndex + Number(currentIndex < Number(carouslImagesLength) - 1);

    this.setState({ venueCarouselIndex: Number(isLeftClick ? prevSlide : nextSlide) });
  };

  handleFileChange = (event: any) => {
    const fileList = event.target.files;

    this.setState(prevState => ({
      businessLicenseImage: [...prevState.businessLicenseImage, ...fileList],
      businessLicenseError: ''
    }));

  };

  onSelectCountry = (event: any) => {
    const countryName = event.target.textContent;
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const states = State.getStatesOfCountry(countryCode);
    this.setState({
      country: countryName,
      countryError: false,
      states,
      stateValue: '',
      city: '',
      countryCode
    });
  }

  onSelectCountryAndCities = (event: any) => {
    const countryName = event.target.textContent;
    const countryCode = this.state.countries.find((country) => country.name === countryName)?.isoCode || '';
    const cities = City.getCitiesOfCountry(countryCode) || [];

    this.setState({
      country: countryName,
      countryError: false,
      states: [],
      stateValue: '',
      city: '',
      countryCode,
      cities
    });
  }

  getDisplayCountry = () => {
    return this.state.country || this.state.userDetails?.attributes?.profile_data?.attributes?.country || '';
  }

  getDisplayCity = () => {
    return this.state.city || this.state.userDetails?.attributes?.profile_data?.attributes?.city || '';
  }

  onSelectState = (event: any) => {
    const stateName = event.target.textContent;
    const stateObj = this.state.states.find((state) => state.name === stateName);
    const { countryCode, isoCode } = stateObj || { countryCode: '', isoCode: '' };

    const cities = City.getCitiesOfState(countryCode, isoCode);
    const allCountryCities: any = City.getCitiesOfCountry(this.state.countryCode);

    const resultCities = (cities.length && cities) || allCountryCities;

    this.setState({
      stateValue: event.target.textContent,
      stateError: false,
      cities: resultCities,
      city: ''
    });
  }

  onSelectCity = (event: any) => {
    this.setState({ city: event.target.textContent, cityError: false })
  }
  getStateOptions() {
    return this.state.states.length ? this.state.states.map((state) => state.name) : ['Default']
  }

  getCityOptions() {
    return this.state.cities.length ? this.state.cities.map((city) => city.name) : ['Default']
  }

  setAddress = (event: any) => {
    this.setState({ spaAddress: event.target.value, addressError: false })

  }

  getStatesOfCountry = () => {
    const countryIsoCode = this.state.countries.find(country => country.name === this.state.country)?.isoCode;
    const states = State.getStatesOfCountry(countryIsoCode);
    return states.map(state => state.name);
  }

  getCitiesOfState = () => {
    const countryIsoCode = this.state.countries.find(country => country.name === this.state.country)?.isoCode;
    const states = State.getStatesOfCountry(countryIsoCode);
    const state = states.find(state => state.name === this.state.stateValue);
    const cities = state?.isoCode && City.getCitiesOfState(countryIsoCode!, state.isoCode) || [];
    return cities.map(city => city.name);
  }

  getTruthyValue(firstValue: UserDetails['attributes'] | undefined): UserDetails {
    return {
      id: "",
      attributes: firstValue || initialUserDetails.attributes,
    };
  }

  getTruthyValueOrDefault<T>(value: T | undefined | null, defaultValue: T): T {
    return value !== undefined && value !== null ? value : defaultValue;
  }

  getLogicalValue(firstValue: boolean, secondValue: React.ReactNode): React.ReactNode | null {
    return firstValue ? secondValue : null;
  }

  getLanguageBasedStyle<T>(isArabic: boolean | undefined, arabicValue: T, defaultValue: T): T {
    return isArabic ? arabicValue : defaultValue;
  }

  getTernaryValue<T>(condition: boolean, firstValue?: T, secondValue?: T): T | string {
    return condition ? (firstValue || '') : (secondValue || '');
  }

  getCombinedLogicalValue(condition1: boolean, condition2: boolean): boolean {
    return condition1 && condition2;
  }

  getAllCombinedLogicalValues(...conditions: boolean[]): boolean {
    return conditions.every(condition => condition);
  }

  getEitherLogicalValue<T>(value1: Nullable<T>, value2: Nullable<T>): Nullable<T> {
    return value1 || value2;
  }

  getLogicalAndValue(firstObj: Record<string, any>, secondObj: Record<string, any>) {
    return firstObj || secondObj
  }

  setOpenCancelModal = (open?: boolean, bookingId?: number, service_name?: string) => {
    this.setState({
      openCancelModal: open,
      cancelModalServiceName: service_name,
      bookedID: bookingId
    });
  };

  setOpenConfirmationModal = (open: boolean, config = {}) => {
    this.setState({
      openConfirmationModal: open,
      modalConfig: { ...this.state.modalConfig, ...config },
    });
  };

  setOpenNotificationModal = (open: boolean) => {
    this.setState({
      openNotificationModal: open,
    });
  };

  handleOpenModal = (modalConfig?: ModalConfig) => this.handleUserAction() && this.setOpenConfirmationModal(true, modalConfig);

  setCurrencyList = (currencyList: Currency[]) => {
    this.setState({ currencyList, displayCurrencyList: currencyList })
  }

  handleSearchChange = (searchTerm: string) => {
    const copyCurrencyList = this.state.currencyList
    const filteredItems = copyCurrencyList.filter((curr) =>
      curr.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    this.setState({ displayCurrencyList: filteredItems });
  }

  handleCloseSnackBar = () => {
    this.setState({ currencyUpdated: false })
  }

  handleLogout = () => {
    this.setOpenConfirmationModal(false);
    this.logout();
  };

  handleDeleteAccount = async () => {
    this.setOpenConfirmationModal(false);
    const success = await this.deleteUser();
    if (success) {
      this.props.navigation.navigate('EmailAccountLoginBlock');
    }
  }

  handleOpenUpdateModal = (type: UpdateType) => {
    this.handleUserAction() && this.setState({
      openUpdateModal: true,
      updateType: type,
    });
  };

  handleCloseUpdateModal = () => {
    this.setState({ openUpdateModal: false }, () => {
      this.getSpaUserProfile();
    });
  };

  handleEmailUpdate = (newEmail: string) => {
    this.setState({
      email: newEmail,
    });
  };

  handlePhoneNumberUpdate = (phoneNumber: string) => {
    this.setState({ phoneNumber })
  }

  onCancelBooking = async (bookingId?: number) => {
    if (bookingId !== undefined) {
      await this.updateBookingStatus(bookingId);
      this.setState(prevState => {
        const updatedBookedBookings = prevState.bookedBookings.filter(booking => booking.id !== bookingId);
        const cancelledBooking = prevState.bookedBookings.find(booking => booking.id === bookingId);

        const updatedCancelledBookings = cancelledBooking
          ? [...prevState.cancelledBookings, cancelledBooking]
          : prevState.cancelledBookings;

        const updatedCount = {
          completed: prevState.count.completed,
          booked: prevState.count.booked - 1,
          cancelled: prevState.count.cancelled + 1,
        };

        return {
          bookedBookings: updatedBookedBookings,
          cancelledBookings: updatedCancelledBookings,
          count: updatedCount,
          openCancelModal: false,
        };
      });
    }
  };

  updateBookingStatus = async (bookingId?: number) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancelBookingAPI}?id=${bookingId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    return new Promise((resolve, reject) => {
      try {
        runEngine.sendMessage(requestMessage.id, requestMessage);
        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  gotoBookingDetails = (id: number) => {
    const { buttonMode, bookedBookings, completedBookings, cancelledBookings } = this.state;

    let currentBookings = [];
    switch (buttonMode) {
      case 'completed':
        currentBookings = completedBookings;
        break;
      case 'booked':
        currentBookings = bookedBookings;
        break;
      case 'cancelled':
        currentBookings = cancelledBookings;
        break;
      default:
        break;
    }

    const bookings = currentBookings.find(booking => booking.id === id);
    if (bookings) {
      const url = `/BookingDetails/${id}/buttonMode=${buttonMode}`;
      const message = new Message(getName(MessageEnum.NavigationBookingDetails));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationPayLoadMessage), { bookings, buttonMode });
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message);
      window.history.replaceState(null, "New Page Title", url);
    }
  };

  handleEdit() {
    this.setState({
      isEditMode: true,
      editedDetails: {
        ...this.state.userDetails.attributes
      }
    });
  }

  handleUpdateProfileInfo = async (details: UserDetails['attributes']) => {
    try {
      const { full_name, email, phone_number, country_code, profile_data, gender } = details;
      const { city, country } = profile_data.attributes;
      const user_id = await getStorageData("user_id");
      const userProfileUrl = await getStorageData("avatarImageUrl");

      const formData = new FormData();

      formData.append('account[full_name]', full_name);
      formData.append('account[email]', email);
      if (phone_number) {
        formData.append('account[phone_number]', phone_number.toString());
      }
      formData.append('account[country_code]', country_code?.toString() || "91");
      formData.append('account[gender]', gender);
      formData.append('account[profile][country]', country);
      formData.append('account[profile][city]', city);
      formData.append('account[profile][account_id]', user_id);

      await this.updatProfileApiCall(formData);

      if (userProfileUrl) {
        const photoFormData = new FormData();
        const photoResponse = await fetch(userProfileUrl);
        const blob = await photoResponse.blob();
        photoFormData.append('account[profile_photo]', blob, 'profile_photo.jpg');
        await this.updatProfileApiCall(photoFormData);
      }
    } catch (error) {
    }
  };

  validateUserPassword = (password: string, t: Record<string, string>): boolean => {
    password = password.trim();
    if (password.length < 8) {
      this.setState({ passwordError: t['profile-settings-password-validation-8-chars'] });
      return false;
    }

    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';

    let hasUppercase = false;
    let hasLowercase = false;
    let hasNumber = false;

    for (const char of password) {
      if (uppercaseChars.includes(char)) hasUppercase = true;
      else if (lowercaseChars.includes(char)) hasLowercase = true;
      else if (numberChars.includes(char)) hasNumber = true;
    }

    if (!this.getAllCombinedLogicalValues(
      hasUppercase,
      hasLowercase,
      hasNumber,
    )) {
      this.setState({ passwordError: t['profile-settings-password-validation-full-rules'] });
      return false;
    }

    for (let i = 0; i < password.length - 2; i++) {
      if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
        this.setState({ passwordError: t['profile-settings-password-validation-identical'] });
        return false;
      }
    }
    const commonPasswords = ['password', '123456', 'qwerty', 'admin'];
    if (commonPasswords.includes(password.toLowerCase())) {
      this.setState({ passwordError: t['profile-settings-password-validation-common'] });
      return false;
    }

    const sequences = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < sequences.length - 2; i++) {
      const fragment = sequences.substring(i, i + 3);
      if (password.toLowerCase().includes(fragment)) {
        this.setState({ passwordError: t['profile-settings-password-validation-sequential'] });
        return false;
      }
    }
    return true;
  };

  isValidEmailId(email: string): boolean {
    email = email.trim();
    const atIndex = email.indexOf('@');
    const dotIndex = email.lastIndexOf('.');

    if (email.split('@').length !== 2) {
      return false;
    }

    if (
      atIndex <= 0 ||
      dotIndex <= atIndex + 1 ||
      dotIndex === email.length - 1
    ) {
      return false;
    }

    const localPart = email.slice(0, atIndex);
    if (localPart.length > 64 || localPart.length === 0) {
      return false;
    }

    const domainPart = email.slice(atIndex + 1);
    if (domainPart.length > 255 || domainPart.length === 0) {
      return false;
    }

    if (domainPart.indexOf('.') === -1) {
      return false;
    }

    const validChars =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.-_@';
    for (const char of email) {
      if (!validChars.includes(char)) {
        return false;
      }
    }

    if (email.includes('..')) {
      return false;
    }

    const tld = email.slice(dotIndex + 1);
    if (tld.length < 2) {
      return false;
    }

    return true;
  }

  validateEmailId = (email: string): boolean => {
    return this.isValidEmailId(email);
  };

  updateValidatePhoneNumber = (phoneNumber: string, t: Record<string, string>) => {
    const trimmedPhoneNumber = phoneNumber.replace(/\D/g, '');

    if (trimmedPhoneNumber.length < 10) {
      this.setState({
        showSnack: true,
        snackMessage: t['profile-settings-invalid-phone-number'],
        phoneError: t['profile-settings-invalid-phone-number']
      });
      return false;
    }
    return trimmedPhoneNumber;
  }

  validatePhoneNumber = (phone: string, country: CountryCode): boolean => {
    const phoneNumberWithPrefix = `+${phone}`;

    if (!phone || !country) return false;

    try {
      const phoneNumber = parsePhoneNumber(phoneNumberWithPrefix, country);
      const originalNumber = phone.replace(/\D/g, '');
      const parsedNumber = phoneNumber.nationalNumber.toString();
      const countryCode = getCountryCallingCode(country);
      const originalNumberWithoutCountryCode = originalNumber.replace(countryCode, '');

      const isOriginalNumberPresent = parsedNumber === originalNumberWithoutCountryCode;
      return phoneNumber.isValid() && isOriginalNumberPresent;
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return false;
    }
  };

  validateFullName = (fullName: string): boolean => {
    return fullName.trim().length > 0;
  };
  validateCity = (city: string): boolean => {
    return city.trim().length > 0;
  };
  validateCountry = (country: string): boolean => {
    return country.trim().length > 0;
  };
  validateForm = () => {
    const { editedDetails } = this.state;
    const isEmailValid = this.validateEmailId(editedDetails.email);
    const isFullNameValid = this.validateFullName(editedDetails.full_name);
    const isCityValid = this.validateCity(this.state.city);
    const isCountryValid = this.validateCountry(this.state.country);

    const phoneNumber = this.state.phoneNumber ||
      (this.state.userDetails.attributes.phone_number
        ? this.state.userDetails.attributes.phone_number.toString()
        : null);

    const isPhoneNumberValid = phoneNumber && !this.state.phoneNumberError;

    this.setState({
      cityError: !isCityValid,
      countryError: !isCountryValid,
      phoneNumberError: !isPhoneNumberValid,
    });

    return isEmailValid && isFullNameValid && isCityValid && isCountryValid && isPhoneNumberValid;
  };

  handleSave = async () => {
    if (this.validateForm()) {
      try {
        const { editedDetails, userDetails, city, country, phoneNumber } = this.state;
        const phoneNum = userDetails.attributes.phone_number

        const updatedDetails: UserDetails['attributes'] = {
          ...userDetails.attributes,
          full_name: editedDetails.full_name,
          email: editedDetails.email,
          phone_number: phoneNumber ? Number(phoneNumber) : phoneNum,
          country_code: userDetails.attributes.country_code,
          gender: editedDetails.gender,
          profile_data: {
            attributes: {
              ...(userDetails.attributes.profile_data?.attributes ?? {}),
              city: city || userDetails.attributes.profile_data?.attributes?.city,
              country: country || userDetails.attributes.profile_data?.attributes?.country,
            },
          },
        };

        await this.handleUpdateProfileInfo(updatedDetails);

        this.setState({
          userDetails: {
            ...userDetails,
            attributes: updatedDetails,
          },
          isEditMode: false,
        });
      } catch (error) {
      }
    }
  };


  async getCutomerBookingDetails(category: string) {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerBookingDetailsAPI}?status=${category}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteUser = async () => {
    const authToken = await getStorageData('authToken');
    const userId = await getStorageData('user_id');

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteUserApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteUserEndPoint + `/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCancel = () => {
    this.setState({
      isEditMode: false,
      editedDetails: this.state.userDetails?.attributes || {}
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | { name: string; value: unknown }>) => {
    const { name, value } = e.target;
    this.setState({
      editedDetails: {
        ...this.state.editedDetails,
        [name]: value
      }
    });
  };

  handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (name) {
      this.setState({
        editedDetails: {
          ...this.state.editedDetails,
          [name]: value
        }
      });
    }
  };


  getFormattedPhoneNumber() {
    const { userDetails } = this.state;
    const defaultUserDetails = { attributes: { country_code: '', phone_number: '' } };
    const mergedUserDetails = { ...defaultUserDetails, ...userDetails };
    const countryCode = mergedUserDetails.attributes.country_code ? '+' + mergedUserDetails.attributes.country_code : '';
    const phoneNumber = mergedUserDetails.attributes.phone_number ? mergedUserDetails.attributes.phone_number.toString().replace(/\D/g, '') : '';
    if (!countryCode || !phoneNumber) return '';
    return countryCode + ' ' + phoneNumber;
  }

  logout = async () => {
    await removeStorageData("user_id");
    await removeStorageData("userRole");
    await removeStorageData("loginDetail");
    await removeStorageData("authToken");
    await removeStorageData("bookingInfo");
    await removeStorageData("selectedTimeSlotPrice")
    await removeStorageData("avatarImageUrl")
    await removeStorageData("appliedCoupon");
    await removeStorageData("selectedTimeSlotPrice")
    await removeStorageData("selectedCountry")
    await removeStorageData("navigationPath")
    await removeStorageData("countryDataInfo")

    window.location.href = "/";
  }

  addButtonStyles = (btnName: string) => {
    const buttonMode = this.state.buttonMode;
    const name = buttonMode === btnName ? buttonMode : 'defaultStyles';

    const newStyles: any = {
      completed: { border: '1px solid #059669', backgroundColor: '#D1FAE5' },
      booked: { border: '1px solid #D97706', backgroundColor: '#FEF3C7' },
      cancelled: { border: '1px solid #DC2626', backgroundColor: '#FEE2E2' },
      defaultStyles: { border: '#CBD5E1', backgroundColor: '#FFFFFF' },
    };

    return newStyles[name];
  };

  setWebLink = (event: any) => {
    this.setState({ webLink: event.target.value })
  }

  setSpaName = (event: any) => {
    this.setState({
      spaName: event.target.value, spaNameError: false
    });
  };

  updateProfileProperties = (APIPayload: Record<string, string | number | File[] | FileWithId[]>) => {
    const isPayLoad = Object.values(APIPayload).some((payload) => {
      return Array.isArray(payload) ? payload.length : payload
    });

    if (isPayLoad) {
      this.setState({ uploading: true });
      const completePayload = {
        ...APIPayload,
        'account[profile][account_id]': this.state.userDetails?.id
      }
      this.updatProfileApiCall(getFormDataFromPayload(completePayload));
    } else {
      this.setState({ isLicenseCertificationPopup: false })
    }
  };

  handleSubmitForReview = () => {
    const { spaName, webLink, businessLicenseImage } = this.state;
    const uploadedLicense = businessLicenseImage.find(this.filterCallBack);
    const isValid = [
      spaName,
      uploadedLicense
    ].every((formItem) => Boolean(formItem));

    if (isValid) {
      const APIPayload = {
        "spa_name": spaName,
        "documents[]": businessLicenseImage,
        "website_link": webLink
      }
      this.updateSpaNameApiCall(getFormDataFromPayload(APIPayload))
    } else {
      this.setState({ spaNameError: !spaName });
      !uploadedLicense && this.setState({ businessLicenseError: "Please upload licence" })
    }
  }

  handleSettingsChange = async (currencyValue: string) => {
    const authToken = await getStorageData('authToken');
    const user_id = await getStorageData('user_id');
    const headers = {
      token: authToken
    };
    const APIPayload = {
      'account[currency]': currencyValue
    }
    this.spaOwnerSettingsApiCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: "PATCH",
        endPoint: configJSON.updateSpaOwnerSettingsEndPoint + `?id=${user_id}`,
        headers: headers,
        body: getFormDataFromPayload(APIPayload)
      },
      runEngine
    );
    // Set currency to be applied immediatelly
    setStorageData('currency', findCurrByCode(currencyValue));
  }

  getOptionLabel = (option: any) => {
    return option;
  };

  getOptionSelected = (option: any, value: any) => {
    return option === value;
  };

  handleCancelEditLocation = () => {
    this.setState({ isEditLocationPopup: false })
  }

  deleteDocumentsByTypes = () => {
    const removableFiles = this.state.idsToRemoveFiles;
    for (let key in removableFiles) {
      removableFiles[key as APITypesKeys]
        .forEach((id: string) => this.handleDeleteDocument(id, key)
        );
    }
  }

  getOnlyFiles = (files: FileWithId[]) => files.filter((file) => !file.id);

  uploadDocuments = async () => {
    const { businessLicenseImage, certificateImage } = this.state;
    const { licenses, certificates } = this.state.userDetails?.attributes || {};

    const [licensesPresent, certificatesPresent] = [
      businessLicenseImage.length || licenses?.length,
      certificateImage.length || certificates?.length
    ];

    const isValid = licensesPresent && certificatesPresent;

    if (isValid) {
      this.updateProfileProperties(
        {
          'account[licenses][]': this.getOnlyFiles(this.state.businessLicenseImage),
          'account[certificates][]': this.getOnlyFiles(this.state.certificateImage),
          'account[other_files][]': this.getOnlyFiles(this.state.otherImages)
        }
      );
      this.deleteDocumentsByTypes();
    } else {
      !licensesPresent && this.setState({ businessLicenseError: "Please upload licence" });
      !certificatesPresent && this.setState({ certificateError: "Please upload certificates" });
    }
  }

  handleUpdateSpaProfileAddres = () => {
    const { country, stateValue, city, spaAddress } = this.state;
    const isValid = [
      country,
      stateValue,
      city,
      spaAddress
    ].every((formItem) => Boolean(formItem));
    if (isValid) {
      const APIPayload = {
        "account[profile][address]": spaAddress,
        "account[profile][city]": city,
        "account[profile][country]": country,
        "account[profile][postal_code]": stateValue
      }
      this.updatProfileApiCall(getFormDataFromPayload(APIPayload))
    } else {
      this.setState({
        countryError: !country,
        cityError: !city,
        stateError: !stateValue,
        addressError: !spaAddress
      })
    }
  }

  updateSpaNameApiCall = async (formData: any) => {

    const user_id = await getStorageData('user_id');
    const headers = {
      token: ''
    };
    this.userSpaNameAPICallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: "PUT",
        endPoint: configJSON.userSpaNameAPIEndPoint + `?id=${user_id}`,
        headers: headers,
        body: formData
      },
      runEngine
    )
  }

  handleEditSPALocation = (
    {
      country,
      state,
      city,
      address
    }: {
      country?: string,
      state?: string,
      city?: string,
      address?: string
    }) =>
    this.setState({
      country: String(country),
      stateValue: String(state),
      city: String(city),
      spaAddress: String(address)
    })

  SpaTodatStatusOption = (option: string) => {
    const { t } = getTranslationConfig();
    switch (option) {
      case t['business-information-spa-status-open']:
        return '#059669';
      case t['business-information-spa-status-closed']:
        return '#DC2626';
      case t['business-information-spa-partially-closed']:
        return '#D97706';
      default:
        return 'inherit';
    }
  };

  handelSpatimingSlot = (item: any, isArabic: any) => {
    this.setState({
      anchorEl: null,
      updatedSpaTiming: this.getLanguageBasedStyle(isArabic, item.labelAr, item.labelEn),
      originalSpaTiming: item.labelEn,
      addDisabled: false
    })
  }
  handleCancelSpatimingPopUp = () => {
    this.setState({ isSpaTimingPopup: false })
  }

  closeFilesPopOver = () => {
    this.setState({ anchorElLicence: [], anchorElCertificate: [] });
  }

  closeTimePopOver = () => this.setState({ anchorEl: null });

  handleEditSpaTimingPopup = () => {
    if (this.handleUserAction()) {
      const {
        open_timing: openTiming,
      } = this.state.userDetails?.attributes || {};
      this.setState({ isSpaTimingPopup: true, updatedSpaTiming: this.getDefaultTiming(openTiming) })
    }
  }

  isAndConditionRendering = (condition: any, value: any) => {
    return condition && value;
  }
  isConditinalRendering = (condition: any, value1: any, value2: any) => {
    return condition ? value1 : value2;
  }

  calculateDotsDimensions() {
    const slidesNumber = this.state.userDetails?.attributes?.carousal_images?.length;
    const highSlideEdge = 6;
    const lowSlideEdge = 4;
    // Handle case if there is only one slide
    const multiplier = slidesNumber && slidesNumber > 1 ? (highSlideEdge - lowSlideEdge) / (slidesNumber - 1) : 0;

    return new Array(slidesNumber)
      .fill(highSlideEdge)
      .map((_, index) => highSlideEdge - Math.abs(this.state.venueCarouselIndex - index) * multiplier);
  }

  goToLocaton = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLocationSettingsProfile)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), { locationAddress: this.state.locationAddress });

    this.send(msg);
  }

  goToSettings = (isWiped?: boolean) => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationBasicInformation)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), { locationAddress: isWiped ? defaultLocation : this.state.locationAddress });

    this.send(msg);
  }

  gotoSubscriptionPlans = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSubscriptionPlans)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { isUpdate: true });

    this.send(message);
  }

  handleDeleteDocument = async (id: any, type: string) => {
    if (this.handleUserAction()) {
      const authToken = await getStorageData('authToken');

      const headers = {
        "token": authToken,
      };

      this.deleteDocumentApiCallId = callApi(
        {
          contentType: configJSON.validationApiContentType,
          method: "DELETE",
          endPoint: `${configJSON.deleteSpaLicenseandCertificate}?attachment_id=${id}&type=${type}`,
          headers: headers
        },
        runEngine
      )
    }
  }

  addZeroToTime = (time?: number) => {
    const timeStr = String(time);
    return timeStr.length > 1 ? timeStr : `0${timeStr}`;
  }

  setAboutUs = (value?: string) => this.setState({ aboutUs: value });
  setSameTimeChecked = () => this.setState({ sameTimeChecked: (!this.state.sameTimeChecked) });
  setIsDurationFrom = (value: boolean) => this.setState({ isDurationFrom: value });
  setOpenPrefferedTiming = (value: boolean) => this.setState({ addPrefferTiming: value });
  setDatesRange = (value: any) => this.setState({ datesRange: value });

  setCalendarPopUp = (value: boolean) => this.setState({ showCalendarPopUp: value });

  getTimeColor = ({ isFromTime }: { isFromTime: boolean }) => isFromTime ? '#398378' : '#94A3B8';

  getTimeString = (mode: 'from' | 'to'): string => {
    const { durationFrom, durationTo } = this.state.durationPicker;
    return mode === 'from' ?
      `${durationFrom.hours}:${durationFrom.minutes} am` :
      `${durationTo.hours}:${durationTo.minutes} pm`;
  }

  getDefaultTiming = (openTiming?: string) => (openTiming === 'Close' || !openTiming) ? spaTimingSlots[0].labelEn : openTiming;

  submitTimingForm = () => {
    this.setState({ isSpaTimingPopup: false });
    const updatedSpaTiming = this.state.originalSpaTiming;
    const timingData = [];

    const [todayStartTime, todayEndTime] = updatedSpaTiming.includes('-')
      ? this.state.originalSpaTiming.split(' - ')
      : ['', ''];

    const commonProps = {
      "status": this.state.spaTodayStatus.toLowerCase(),
      "same_timing": this.state.sameTimeChecked,
    }

    if (this.state.openingDatesList.length > 0) {
      const existingTiming = this.state.originalSpaTiming;
      const [existingStartTime, existingEndTime] = existingTiming.includes('-')
        ? existingTiming.split(' - ')
        : ['', ''];

      const todayData = {
        "start_time": todayStartTime || existingStartTime,
        "end_time": todayEndTime || existingEndTime,
        "date": [...this.state.openingDatesList],
        ...commonProps
      };
      timingData.push(todayData);
    }

    const otherDaysStartTime = this.getTimeString('from');
    const otherDaysEndTime = this.getTimeString('to');

    if (this.state.openingDates &&
      this.state.durationPicker &&
      otherDaysStartTime &&
      otherDaysStartTime !== '00:00 am') {
      const otherDaysData = {
        "start_time": otherDaysStartTime,
        "end_time": otherDaysEndTime,
        "date": this.state.openingDatesList,
        ...commonProps
      };
      timingData.push(otherDaysData);
    }

    if (timingData.length > 0) {
      const timingAPIPayload = {
        'account[profile][account_id]': this.state.userDetails?.id,
        'account[spa_timing]': JSON.stringify(timingData)
      };

      this.updatProfileApiCall(getFormDataFromPayload(timingAPIPayload));
    }
  }

  getDuration = (): string =>
    `${this.getTimeString("from")} - ${this.getTimeString("to")}`;

  getCommonDuration = (title: string) =>
    this.state.durationPicker.hasStarted ?
      `${this.getTimeString('from')} - ${this.getTimeString('to')}` :
      title

  getUniqArray = (targetArray: string[]) => Array.from(new Set(targetArray))

  handleDates = (callendarDate: any) => {
    const { isArabic } = getTranslationConfig();
    const allDatesRange: string[] = [];
    Array.isArray(callendarDate) && callendarDate.forEach((dateObject: any) => {
      dateObject.forEach((innerData: DateObject) => {
        const { year, month, day } = innerData;
        const fullDate = `${year}-${this.addZeroToTime(Number(month))}-${this.addZeroToTime(Number(day))}`;
        allDatesRange.push(fullDate);
      })
    });

    const startDate = allDatesRange[0];
    const endDate = allDatesRange[allDatesRange.length - 1];

    const dateRange = [];
    for (let m = moment(startDate); m.diff(moment(endDate), 'days') <= 0; m.add(1, 'days')) {
      dateRange.push(m.format('YYYY-MM-DD'));
    }

    const openingDatesList = dateRange;

    const rangeDates = [openingDatesList[0], openingDatesList[openingDatesList.length - 1]];

    const locale = this.getLanguageBasedStyle(isArabic, 'ar-SA', 'en-GB');

    const openingDates = this.getUniqArray(rangeDates).map((dateString) => {
      return new Date(dateString).toLocaleString(
        locale,
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        })
    }).join(' - ');
    this.setState({ openingDates, openingDatesList, openingUIDates: openingDates })
  }

  onDurationChange = (duration: DurationPickerObjInit) => {
    const hours = this.addZeroToTime(duration.hours);
    const minutes = (durationConversion as Record<number, string>)[Number(duration.minutes)];

    // Handele empty time values
    if (minutes || hours) {
      const resDuration = this.state.isDurationFrom ?
        { durationFrom: { hours, minutes } } :
        { durationTo: { hours, minutes } }

      this.state.durationPicker &&
        this.setState({
          durationPicker: {
            ...this.state.durationPicker,
            ...resDuration,
            hasStarted: true
          }
        })
    }
  };

  setApiError = (value: boolean) => this.setState({ apiError: value });

  getTrullyVal = (first?: string, second?: string, third?: string) => first || second || third;

  getTernaryNodeOutput = (condition: boolean, first: React.ReactNode, second: React.ReactNode) => condition ? first : second;

  setAnchor = (
    index: number,
    setterName: PopoverAnchor,
    value: HTMLElement | null
  ) => {
    const anchorCopy = this.state[setterName];
    anchorCopy[index] = value;
    this.setState({ [setterName]: anchorCopy } as unknown as Pick<S, keyof S>);
  }

  addFilesForRemove = (uploadedFiles: FileWithId[], index: number, popoverAnchor: PopoverAnchor) => {
    const documentId = uploadedFiles[index].id;

    const objectType = {
      licenses: popoverAnchor === 'anchorElLicence',
      certificates: popoverAnchor === 'anchorElCertificate',
      carousal_images: popoverAnchor === 'anchorElOtherFiles'
    }
    documentId && this.setState({
      idsToRemoveFiles: {
        ...this.state.idsToRemoveFiles,
        ...(objectType.licenses && {
          licenses: [
            ...this.state.idsToRemoveFiles.licenses,
            documentId
          ]
        }),
        ...(objectType.certificates && {
          certificates: [
            ...this.state.idsToRemoveFiles.certificates,
            documentId
          ]
        }),
        ...(objectType.carousal_images && {
          carousal_images: [
            ...this.state.idsToRemoveFiles.carousal_images,
            documentId
          ]
        }),
      }
    });
    this.setAnchor(index, popoverAnchor, null);
  }

  checkAllProgressCompleted = () => {
    const isProgressComp = this.state.progressBars.every((progress) => progress === configJSON.edgeInterval);
    isProgressComp &&
      this.setState({
        isLicenseCertificationPopup: false,
        loading: false,
      });
  }

  setExpectedProgress = (index: number) => {
    const edge = configJSON.edgeInterval;
    const progressInterval = setInterval(() => {
      const { progressBars } = this.state;
      const currentBar = progressBars[index];
      const indexValue = currentBar || 0;

      if (currentBar === edge) {
        this.checkAllProgressCompleted();
        clearInterval(progressInterval);
      } else {
        const totalProgress = incrementRandomNumber(indexValue);
        const resProgress = totalProgress >= edge ? edge : totalProgress;
        progressBars[index] = resProgress;
      }
      if (indexValue <= edge) {
        this.setState({ progressBars: progressBars });
      }
    }, 300);
  }

  findTodayData = () => {
    const spaTiming = (this.state.userDetails?.attributes.spa_timings || []).slice().reverse();
    const todayData = spaTiming?.find((timing: TimeSlot) =>
      timing.date?.map((date) => new Date(date).getDay()).includes(new Date().getDay()) ||
      timing.same_timing
    );
    return ({
      todayTiming: `${todayData?.start_time} - ${todayData?.end_time}`,
      todayStatus: (todayData?.status.toLocaleLowerCase().replace(/\b[a-z,A-Z]/g, (strg: string) => strg.toUpperCase()) || 'Closed')
    })
  }

  formatDateToTime = (dateString: string, isArabic: boolean, isDate?: boolean) => {
    const locale = this.getLanguageBasedStyle(isArabic, 'ar', 'en');
    return moment(dateString, 'h:mm A').locale(locale).format(this.getLanguageBasedStyle(isDate, 'YYYY-MM-DD', 'hh:mm A'));
  }

  getStatusNode = () => {
    const { t, isArabic } = getTranslationConfig();
    const { todayTiming, todayStatus } = this.findTodayData();
    const statusMap = {
      Open: t['business-information-spa-status-open'],
      Closed: t['business-information-spa-status-closed'],
    };

    const timings = todayTiming.split('-');
    const formattedStartTiming = this.formatDateToTime(timings[0].trim(), isArabic);
    const formattedEndTiming = this.formatDateToTime(timings[1].trim(), isArabic);

    const formattedTiming = `${formattedStartTiming} - ${formattedEndTiming}`;

    return (
      <>
        {statusMap[todayStatus as keyof typeof statusMap]} {t['business-information-spa-timing-today']}
        {todayStatus === 'Open' && <span style={{ fontWeight: 'bold', direction: 'ltr', margin: '0 10px' }}>{formattedTiming}</span>}
      </>
    );
  }

  getStatusImg = () => {
    const { todayStatus } = this.findTodayData();
    const openImg = todayStatus === 'Open' && clockOpened;
    const closedImg = todayStatus === 'Closed' && clockClosed;

    return openImg || closedImg || clockWait;
  }

  getOpenTiming = (openTiming: string, futureOpenTiming: string, defaultValue: string = '09:00 am - 06:00 pm') => {
    if (openTiming && openTiming !== 'Close') {
      return openTiming;
    } else if (futureOpenTiming && futureOpenTiming !== 'undefined - undefined') {
      return futureOpenTiming;
    } else {
      return defaultValue;
    }
  }

  isTargetPopover = (index: number) =>
    Boolean(this.state.anchorSlots) && this.state.currentPopperIndex === index;

  reduceSlotIndex = () =>
    this.state.currentSlotIndex > 0 && this.setState({ currentSlotIndex: this.state.currentSlotIndex - 1 });

  handlePopover = (event: React.MouseEvent<SVGSVGElement>) => this.setState({
    anchorInfoEl: event.currentTarget,
    openInfoPopover: !this.state.openInfoPopover
  });

  handleClosePopover = () => this.setState({
    anchorInfoEl: null,
    openInfoPopover: false
  });

  goToMyBookings = () => {
    const msg: Message = new Message(getName(this.state.isCustomer ? MessageEnum.NavigationMyBookings : MessageEnum.NavigationBooking));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  removeTimeZoneOffset(dateTimeString: string): string {
    if (!dateTimeString) {
      return "N/A";
    }

    const zIndex = dateTimeString.indexOf('Z');
    if (zIndex !== -1) {
      return dateTimeString.slice(0, zIndex);
    }

    const plusIndex = dateTimeString.indexOf('+00:00');
    if (plusIndex !== -1) {
      return dateTimeString.slice(0, plusIndex).trim();
    }

    return dateTimeString;
  }

  formatTime(dateTimeString: string, isArabic?: boolean) {
    const locale = isArabic ? 'ar' : 'en';
    const dateTimeWithoutOffset = this.removeTimeZoneOffset(dateTimeString);

    const momentDate = moment(dateTimeWithoutOffset).locale(locale);

    return momentDate.isValid() ? momentDate.format('hh:mm A') : '';
  }

  formatTimeRange(start: string, end: string, isArabic?: boolean) {
    const startFormatted = this.formatTime(start, isArabic);
    const endFormatted = this.formatTime(end, isArabic);

    return isArabic ? `${endFormatted} - ${startFormatted}` : `${startFormatted} - ${endFormatted}`;
  }

  sortBookings(bookings: any[]) {
    return bookings.sort((a, b) => {
      return new Date(b.id).getTime() - new Date(a.id).getTime();
    });
  }

  setLocationAddress = (value: LocationAddress) => this.setState({ locationAddress: value });
  setPlaces = (value: Places) => this.setState({ places: value });
  setCoords = (value: LatLng | null) => this.setState({ coords: value });


  handleNotificationsApiResponse = (responseJson: any) => {
    const { t } = getTranslationConfig();
    if (responseJson.data?.length > 0) {
      this.setState({
        notifications: responseJson.data,
        notificationsLoading: false,
        notificationErrorMessage: '',
      });
    } else {
      this.setState({
        notifications: [],
        notificationsLoading: false,
        notificationErrorMessage: t['notifications-error-message'],
      });
    }
  }

  async getNotifications() {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetNotificationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationsAPIEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNotification = async (notificationId: string) => {
    const authToken = await getStorageData('authToken');

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteNotificationApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsAPIEndPoint + `/${notificationId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleNotificationDeletion(notificationId);
    return true;
  };

  handleNotificationDeletion = (notificationId: string) => {
    this.setState((prevState) => ({
      notifications: prevState.notifications.filter((notification) => notification.id !== notificationId && notification.attributes.id !== parseInt(notificationId)),
    }));
  };

  updateNotificationStatus = async (notificationId: string, data: any) => {
    const authToken = await getStorageData('authToken');
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateBookingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsAPIEndPoint + `/${notificationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleNotificationUpdate(notificationId, true);
    return true;
  };

  handleNotificationUpdate = (notificationId: string, isRead: boolean) => {

    this.setState((prevState) => {
      const updatedNotifications = prevState.notifications.map((notification) =>
        notification.id === notificationId ? { ...notification, attributes: { ...notification.attributes, is_read: isRead } } : notification
      );

      return {
        notifications: updatedNotifications
      };
    });
  };

  handleNotificationClick = (notification: Notification) => {
    const data = {
      data: {
        contents: "",
      },
    };

    this.updateNotificationStatus(notification.id, data);
    this.setState({
      openNotificationModal: true,
      selectedNotification: notification,
    });
  };


  getArabicValues = (isArabic: boolean) => isArabic ? {
    inputPadding: { paddingRight: '32px' },
    avatarMargin: { marginRight: "200px" },
    descriptionJustify: { justifyContent: 'flex-end' },
    phoneTextAlign: { textAlign: 'right' },
    closeIconMargin: { marginRight: 'auto' }
  } : {
    avatarMargin: { marginLeft: "200px" },
    closeIconMargin: { marginLeft: 'auto' }
  };

  setShowRestrictedPrompt = (open: boolean, config = {}) => {
    this.setState({
      showRestrictedPrompt: open,
      modalConfig: { ...this.state.modalConfig, ...config },
    });
  };

  handleSubscription = (isOwner: boolean) => {
    if (!((this as any).subscriptionRef.current) && isOwner) {
      this.getUserCurrentSubscription();

      (this as any).subscriptionRef.current = true;
    }
  }

  getLabelStyle = () => {
    return this.state.isCustomer ? {
      height: '100%',
      background: `rgba(0, 0, 0, 0.3) url(${profileCover}) center bottom 30% / 100% auto no-repeat`,
      zIndex: 0,
    } : {}
  }

  goToCustomerCouponsPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationCustomerCoupons)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleUserAction = (expired?: boolean) => applyUserAction(this, expired);

  setAvatarUpldPopover = (anchorEl: EventTarget | null) => this.handleUserAction() && this.setState({ anchorEl });

  checkRestriction = (property: string, value: any) => this.handleUserAction() && this.setState({ [property]: value } as Pick<S, keyof S>);

  getCorrectInputType = () => `${getIsRestricted() ? 'text' : 'file'}`

  filterCallBack = (license: File & { id: string }) => !license.id

  getInitFiles = (files: any) => files.filter((file: any) => file.id);

  setInitUserFiles = () => {
    const {
      businessLicenseImage,
      certificateImage,
      otherImages
    } = this.state;

    this.setState({
      businessLicenseImage: this.getInitFiles(businessLicenseImage),
      certificateImage: this.getInitFiles(certificateImage),
      otherImages: this.getInitFiles(otherImages),
    })
  }

  handleAutocompleteOpen = () => {
    this.setState({ openDrop: true });
  };

  handleAutocompleteClose = () => {
    this.setState({ openDrop: false });
  };

  handleAutocompleteToggle = () => {
    this.setState((prevState) => ({ openDrop: !prevState.openDrop }));
  };

  handleCountryAutocompleteOpen = () => {
    this.setState({ countryOpen: true, });
  };

  handleCountryAutocompleteClose = () => {
    this.setState({ countryOpen: false });
  };

  handleStateAutocompleteOpen = () => {
    this.setState({ stateOpen: true, countryOpen: false });
  };

  handleStateAutocompleteClose = () => {
    this.setState({ stateOpen: false });
  };

  handleCityAutocompleteOpen = () => {
    this.setState({ cityOpen: true, });
  };

  handleCityAutocompleteClose = () => {
    this.setState({ cityOpen: false });
  };

  handleCountryAutocompleteToggle = () => {
    this.setState((prevState) => ({ countryOpen: !prevState.countryOpen, stateOpen: false, cityOpen: false }));
  };

  handleStateAutocompleteToggle = () => {
    this.setState((prevState) => ({ stateOpen: !prevState.stateOpen, countryOpen: false, cityOpen: false }));
  };

  handleCityAutocompleteToggle = () => {
    this.setState((prevState) => ({ cityOpen: !prevState.cityOpen, countryOpen: false, stateOpen: false }));
  };

  getDurationObject(categorySlot: any): { id: number; duration: string } {
    const durationViewString = getTransDuration(categorySlot.slotData.duration);
    return {
      id: categorySlot.slotData.id,
      duration: durationViewString,
    };
  }

  formatDate = (dateStr: string | undefined, isArabic?: boolean) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) return '';
    const day = date.getDate();
    const locale = isArabic ? 'ar-eg' : 'default';
    const month = date.toLocaleString(locale, { month: 'long' });
    return `${day} ${month}`;
  }

  updateReorderedSlotDates = (currentSlots: any) => {
    if (!currentSlots || !Array.isArray(currentSlots) || currentSlots.length === 0) {
      this.setState({ reorderedDates: [] });
      return;
    }

    const catalogueDates = currentSlots[0].dates;

    if (!catalogueDates || catalogueDates.length === 0) {
      this.setState({ reorderedDates: [] });
      return;
    }

    const catalogueDays = catalogueDates.map((date: string) => new Date(date).getDay());

    const today = new Date();
    const endDate = new Date(today.getTime() + 365 * 24 * 60 * 60 * 1000);

    const datesRange: string[] = [];

    for (let d = new Date(today); d <= endDate; d.setDate(d.getDate() + 1)) {
      if (catalogueDays.includes(d.getDay())) {
        datesRange.push(
          `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`
        );
      }
    }

    this.setState({ reorderedDates: datesRange });
  }

  getDateTimeRange(data: { date: string | any[]; }[]) {
    const startDate = new Date(data[0].date[0]);
    const endDate = new Date(data[0].date[data[0].date.length - 1]);

    const startDay = startDate.getDate();
    const startMonth = startDate.toLocaleString('default', { month: 'long' });
    const startYear = startDate.getFullYear();

    const endDay = endDate.getDate();
    const endMonth = endDate.toLocaleString('default', { month: 'long' });
    const endYear = endDate.getFullYear();

    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  }

  setOpenLogoutModal = (value: boolean) =>
    this.setState({ openLogoutModal: value });


  handlePopState() {
    this.setState({
      nextPath: '/SpaUserProfile',
      openLogoutModal: true,
    });
  }

  handlePageLoad = () => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', this.handlePopState);
  }


  goToLandingPageVenues = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationVenueMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  // Customizable Area End

}
